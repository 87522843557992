<template>
  <div class="conference-detail-container">
    <div class="head">
      <div class="top">
        <span class="type">[会议成果]</span>
        <span class="title">{{ detailsData.title }}</span>
      </div>
      <IconButton1
        v-if="detailsData.id"
        :every="detailsData"
        :curcer="detailsData.id"
      ></IconButton1>
      <div class="auther">
        作 者：
        <span @click="goAuther('first')">{{ detailsData.firstAuthor }}</span>
        <span
          @click="goAuther('other', item)"
          v-for="(item, i) in detailsData.articleAuthorList"
          :key="i"
        >
          {{ item.authorName }}&nbsp;
        </span>
        <span @click="goAuther('communication')">{{
          detailsData.communicationAuthor
        }}</span>
      </div>
      <div class="unit">
        作者单位：
        <span
          :class="detailsData.firstAuthorUnitStatus === 1 ? 'canClick' : ''"
          @click="flag = detailsData.firstAuthorUnitStatus === 1"
        >
          {{ detailsData.firstAuthorUnit }}
        </span>
        <span
          @click="handleUnit(item)"
          :class="item.authorUnitStatus === 1 ? 'canClick' : ''"
          v-for="(item, i) in detailsData.articleAuthorList"
          :key="i"
          >{{ item.authorUnit }}&nbsp;</span
        >
        <span
          :class="
            detailsData.communicationAuthorUnitStatus === 1 ? 'canClick' : ''
          "
          @click="flag2 = detailsData.communicationAuthorUnitStatus === 1"
        >
          {{ detailsData.communicationAuthorUnit }}
        </span>
      </div>
    </div>
    <ul class="content">
      <li v-if="detailsData.artSummary" class="msg">
        <div>摘要：</div>
        <span>
          {{ detailsData.artSummary }}
        </span>
      </li>
      <li v-if="detailsData.keyword" class="msg">
        <div>关键词：</div>
        <span>{{ detailsData.keyword }}</span>
      </li>
      <li v-if="detailsData.proceedings" class="msg">
        <div>论文集：</div>
        <span>{{ detailsData.proceedings }}</span>
      </li>
      <li v-if="detailsData.mettingName" class="msg">
        <div>会议名称：</div>
        <span>{{ detailsData.mettingName }}</span>
      </li>
      <li v-if="detailsData.mettingTime" class="msg">
        <div>会议时间：</div>
        <span>{{ detailsData.mettingTime | dayFormate }}</span>
      </li>
      <li v-if="detailsData.mettingAddress" class="msg">
        <div>会议地点：</div>
        <span>{{ detailsData.mettingAddress }}</span>
      </li>
      <li v-if="detailsData.mettingUnit" class="msg">
        <div>主办单位：</div>
        <span>{{ detailsData.mettingUnit }}</span>
      </li>
      <li v-if="detailsData.mettingLevel" class="msg">
        <div>会议级别：</div>
        <span>{{ detailsData.mettingLevel }}</span>
      </li>
      <li v-if="detailsData.doi" class="msg">
        <div>DOI：</div>
        <span>{{ detailsData.doi }}</span>
      </li>
      <li v-if="detailsData.classifyNumber" class="msg">
        <div>分类号：</div>
        <span>{{ detailsData.classifyNumber }}</span>
      </li>
      <li v-if="detailsData.sizeNumber" class="msg">
        <div>页码：</div>
        <span>{{ detailsData.sizeNumber }}</span>
      </li>
    </ul>
    <Other :otherList="otherList"></Other>
    <Modal
      v-model="flag"
      @on-ok="ok(detailsData.firstAuthorUnitId)"
      title="即将离开百家在线"
      ok-text="继续访问"
    >
      <p>您即将进入中文知识网，点击“继续访问”可查看具体内容。</p>
      <p>
        中文知识网是国家知识服务平台融合出版分平台，是中文集团旗下子公司运营的知识服务平台。中文知识网拥有百家在线、百科在线、同行评议等子平台。请放心跳转。
      </p>
    </Modal>
    <Modal v-model="flag1" @on-ok="ok1()" title="即将离开百家在线" ok-text="继续访问">
      <p>您即将进入中文知识网，点击“继续访问”可查看具体内容。</p>
      <p>
        中文知识网是国家知识服务平台融合出版分平台，是中文集团旗下子公司运营的知识服务平台。中文知识网拥有百家在线、百科在线、同行评议等子平台。请放心跳转。
      </p>
    </Modal>
    <Modal
      v-model="flag2"
      @on-ok="ok2(detailsData.communicationAuthorUnitId)"
      title="即将离开百家在线"
      ok-text="继续访问"
    >
      <p>您即将进入中文知识网，点击“继续访问”可查看具体内容。</p>
      <p>
        中文知识网是国家知识服务平台融合出版分平台，是中文集团旗下子公司运营的知识服务平台。中文知识网拥有百家在线、百科在线、同行评议等子平台。请放心跳转。
      </p>
    </Modal>
  </div>
</template>

<script>
import Other from "../components/Other.vue";
import { mapState } from "vuex";
import IconButton1 from "@/components/pager/IconButton1.vue";
import details from "@/mixins/details.js";
export default {
  components: {
    IconButton1,
    Other,
  },
  data() {
    return {
      detailsData: {},
      otherList: [],
    };
  },
  mixins: [details],
  computed: {
    ...mapState(["table"]),
  },
  created() {
    this.getDetails({
      articleType: this.$route.query.articleType,
      articleId: this.$route.query.articleId,
      projectId: 3,
    });
    this.getRead();
  },
  methods: {
    async getRead() {
      await this.$apis.autherServe.readCount({
        articleId: this.$route.query.articleId,
        articleType: this.$route.query.articleType,
      });
    },
    async getDetails(data) {
      const resp = await this.$apis.detailServe.getDetails(data);
      this.detailsData = resp.data.data;
      document.title = this.detailsData.title + "-百家在线";
      this.getWenXian({
        keyword: this.detailsData.keyword,
        title: this.detailsData.title,
        articleType: this.$route.query.articleType,
        type: this.detailsData.type,
        pageNo: 1,
        pageSize: 5,
      });
    },
    async getWenXian(data) {
      const resp = await this.$apis.detailServe.getWenXian(data);
      if(resp.resultCode == 200){
        this.otherList = resp.data.list;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conference-detail-container {
  .head {
    height: 170px;
    border-bottom: 1px dashed #d6d6d6;
    .top {
      display: flex;
      align-items: center;
      .type {
        font-size: 30px;
        color: #999999;
      }
      .title {
        font-size: 30px;
        color: #333333;
        margin: 0 5px;
      }
      .column {
        display: inline-block;
        width: 76px;
        height: 30px;
        background: #00a4ff;
        border: 1px solid #00a4ff;
        border-radius: 11px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
      }
    }
    .auther {
      font-size: 14px;
      color: #999999;
      margin-top: 14px;
      span {
        font-size: 14px;
        color: #00a4ff;
        cursor: pointer;
      }
    }
    .unit {
      margin-top: 14px;
      font-size: 14px;
      color: #999999;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        font-size: 14px;
        margin-right: 10px;
      }
      .canClick {
        color: #00a4ff;
        cursor: pointer;
      }
    }
  }
  .content {
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 40px;
    .msg {
      margin-top: 39px;
      div {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      span {
        margin-top: 10px;
        font-size: 16px;
        color: #333333;
      }
    }
  }
}
</style>
